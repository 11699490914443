export default [
    { state: 'abuja', lgas: ['amac', 'bwari', 'gwagwalada', 'kwali', 'abaji', 'kuje'] },
    { state: 'Lagos', lgas: ['Alimosho', 'Ajeromi-Ifelodun', 'Kosofe', 'Mushin', 'Oshodi-Isolo', 'Ojo', 'Ikorodu', 'Surulere', 'Agege', 'Ifako-Ijaiye', 'Somolu', 'Amuwo-Odofin', 'Lagos Mainland', 'Ikeja', 'Eti-Osa', 'Badagry', 'Apapa', 'Lagos Island', 'Epe', 'Ibeju-Lekki'] },
    { state: 'Rivers', lgas: ['Port Harcourt', 'Obio-Akpor', 'Okrika', 'Ogu–Bolo', 'Eleme', 'Tai', 'Gokana', 'Khana', 'Oyigbo', 'Opobo–Nkoro', 'Andoni', 'Bonny', 'Degema', 'Asari-Toru', 'Akuku-Toru', 'Abua–Odual', 'Ahoada West', 'Ahoada East', 'Ogba–Egbema–Ndoni', 'Emohua', 'Ikwerre', 'Etche', 'Omuma'] },
    { state: 'Niger', lgas: ['Agaie', 'Agwara', 'Bida', 'Borgu', 'Bosso', 'Chanchaga', 'Edati', 'Gbako', 'Gurara', 'Katcha', 'Kontagora', 'Lapai', 'Lavun', 'Magama', 'Mariga', 'Mashegu', 'Mokwa', 'Munya', 'Paikoro', 'Rafi', 'Rijau', 'Shiroro', 'Suleja', 'Tafa', 'Wushishi'] },
    { state: 'abia', lgas: ['Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Ikwuano', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma Ngwa', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi'] },
    { state: 'Adamawa', lgas: ['Demsa', 'Fufore', 'Ganye', 'Girei', 'Gombi', 'Guyuk', 'Hong', 'Jada', 'Lamurde', 'Madagali', 'Maiha', 'Mayo-Belwa', 'Michika', 'Mubi North', 'Mubi South', 'Numan', 'Shelleng', 'Song', 'Toungo', 'Yola North', 'Yola South'] },
    { state: 'Akwa-Ibom', lgas: ['Abak', 'Eastern Obolo', 'Eket', 'Esit-Eket', 'Essien Udim', 'Etim-Ekpo', 'Etinan', 'Ibeno', 'Ibesikpo-Asutan', 'Ibiono-Ibom', 'Ika', 'Ikono', 'Ikot Abasi', 'Ikot Ekpene', 'Ini', 'Itu', 'Mbo', 'Mkpat-Enin', 'Nsit-Atai', 'Nsit-Ibom', 'Nsit-Ubium', 'Obot-Akara', 'Okobo', 'Onna', 'Oron', 'Oruk Anam', 'Ukanafun', 'Udung-Uko', 'Uruan', 'Urue-Offong/Oruko', 'Uyo'] },
    { state: 'Anambra', lgas: ['Aguata', 'Awka North', 'Awka South', 'Anambra East', 'Anambra West', 'Anaocha', 'Ayamelum', 'Dunukofia', 'Ekwusigo', 'Idemili North', 'Idemili South', 'Ihiala', 'Njikoka', 'Nnewi North', 'Nnewi South', 'Ogbaru', 'Onitsha North', 'Onitsha South', 'Orumba North', 'Orumba South', 'Oyi'] },
    { state: 'Bauchi', lgas: ['Bauchi', 'Tafawa Balewa', 'Dass', 'Toro', 'Bogoro', 'Ningi', 'Warji', 'Ganjuwa', 'Kirfi', 'Alkaleri', 'Darazo', 'Misau', 'Giade', 'Shira', 'Jamaare', 'Katagum', 'Itas/Gadau', 'Zaki', 'Gamawa', 'Damban'] },
    { state: 'Bayelsa', lgas: ['Brass', 'Ekeremor', 'Kolokuma Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa'] },
    { state: 'Benue', lgas: ['Ado', 'Agatu', 'Apa', 'Buruku', 'Gboko', 'Guma', 'Gwer East', 'Gwer West', 'Katsina-Ala', 'Konshisha', 'Kwande', 'Logo', 'Makurdi', 'Obi', 'Ogbadibo', 'Ohimini', 'Oju', 'Okpokwu', 'Otukpo', 'Tarka', 'Ukum', 'Ushongo', 'Vandeikya'] },
    { state: 'Borno', lgas: ['Maiduguri', 'Ngala', 'Kala/Balge', 'Mafa', 'Konduga', 'Bama', 'Jere', 'Dikwa', 'Shani', 'Nganzai', 'Askira/Uba', 'Bayo', 'Biu', 'Chibok', 'Damboa', 'Gwoza', 'Hawul', 'Kwaya Kusar', 'Monguno', 'Abadam', 'Gubio', 'Guzamala', 'Kaga', 'Kukawa', 'Magumeri', 'Marte', 'Mobbar'] },
    { state: 'Cross River', lgas: ['Abi', 'Akamkpa', 'Akpabuyo', 'Bekwarra', 'Bakassi', 'Biase', 'Boki', 'Calabar Municipal', 'Calabar South', 'Etung', 'Ikom', 'Obanliku', 'Obubra', 'Obudu', 'Odukpani', 'Ogoja', 'Yakuur', 'Yala'] },
    { state: 'Delta', lgas: ['Ethiope East', 'Ethiope West', 'Okpe', 'Sapele', 'Udu', 'Ughelli North', 'Ughelli South', 'Uvwie', 'Ukwuani', 'Aniocha North', 'Aniocha South', 'Ika North East', 'Ika South', 'Ndokwa East', 'Ndokwa West', 'Oshimili North', 'Oshimili South', 'Bomadi', 'Burutu', 'Isoko North', 'Isoko South', 'Patani', 'Warri North', 'Warri South', 'Warri South West'] },
    { state: 'Ebonyi', lgas: ['Abakaliki', 'Afikpo North', 'Afikpo South (Edda)', 'Ebonyi', 'Ezza North', 'Ezza South', 'Ikwo', 'Ishielu', 'Ivo', 'Izzi', 'Ohaozara', 'Ohaukwu', 'Onicha'] },
    { state: 'Edo', lgas: ['Akoko-Edo', 'Egor', 'Esan Central', 'Esan North-East', 'Esan South-East', 'Esan West', 'Etsako Central', 'Etsako East', 'Etsako West', 'Igueben', 'Ikpoba-Okha', 'Oredo', 'Orhionmwon', 'Ovia North-East', 'Ovia South-West', 'Owan East', 'Owan West', 'Uhunmwonde'] },
    { state: 'Ekiti', lgas: ['Ado-Ekiti', 'Ikere', 'Oye', 'Aiyekire (Gbonyin)', 'Efon', 'Ekiti East', 'Ekiti South-West', 'Ekiti West', 'Emure', 'Ido-Osi', 'Ijero', 'Ikole', 'Ilejemeje', 'Irepodun/Ifelodun', 'Ise/Orun', 'Moba'] },
    { state: 'Enugu', lgas: ['Aninri', 'Awgu', 'Enugu East', 'Enugu North', 'Enugu South', 'Ezeagu', 'Igbo Etiti', 'Igbo Eze North', 'Igbo Eze South', 'Isi Uzo', 'Nkanu East', 'Nkanu West', 'Nsukka', 'Oji River', 'Udenu', 'Udi', 'Uzo-Uwani'] },
    { state: 'Gombe', lgas: ['Akko', 'Balanga', 'Billiri', 'Dukku', 'Funakaye', 'Gombe', 'Kaltungo', 'Kwami', 'Nafada', 'Shongom', 'Yamaltu/Deba'] },
    { state: 'Imo', lgas: ['Aboh Mbaise', 'Ahiazu Mbaise', 'Ehime Mbano', 'Ezinihitte Mbaise', 'Ideato North', 'Ideato South', 'Ihitte/Uboma', 'Ikeduru', 'Isiala Mbano', 'Isu', 'Mbaitoli', 'Ngor Okpala', 'Njaba', 'Nkwerre', 'Nwangele', 'Obowo', 'Oguta', 'Ohaji/Egbema', 'Okigwe', 'Onuimo', 'Orlu', 'Orsu', 'Oru East', 'Oru West', 'Owerri Municipal', 'Owerri North', 'Owerri West'] },
    { state: 'Jigawa', lgas: ['Auyo', 'Babura', 'Biriniwa', 'Birnin Kudu', 'Buji', 'Dutse', 'Gagarawa', 'Garki', 'Gumel', 'Guri', 'Gwaram', 'Gwiwa', 'Hadejia', 'Jahun', 'Kafin Hausa', 'Kaugama', 'Kazaure', 'Kiri Kasama', 'Kiyawa', 'Maigatari', 'Malam Madori', 'Miga', 'Ringim', 'Roni', 'Sule Tankarkar', 'Taura', 'Yankwashi'] },
    { state: 'Kaduna', lgas: ['Birnin Gwari', 'Chikun', 'Giwa', 'Igabi', 'Ikara', 'Jaba', 'Jema\'a', 'Kachia', 'Kaduna North', 'Kaduna South', 'Kagarko', 'Kajuru', 'Kaura', 'Kauru', 'Kubau', 'Kudan', 'Lere', 'Makarf', 'Sabon Gari', 'Sanga', 'Soba', 'Zangon Kataf', 'Zaria'] },
    { state: 'Katsina', lgas: ['Bakori', 'Batagarawa', 'Batsari', 'Baure', 'Bindawa', 'Charanchi', 'Dan Musa', 'Dandume', 'Danja', 'Daura', 'Dutsi', 'Dutsin-Ma', 'Faskari', 'Funtua', 'Ingawa', 'Jibia', 'Kafur', 'Kaita', 'Kankara', 'Kankia', 'Katsina', 'Kurfi', 'Kusada', 'Mai\'Adua', 'Malumfashi', 'Mani', 'Mashi', 'Matazu', 'Musawa', 'Rimi', 'Sabuwa', 'Safana', 'Sandamu', 'Zango'] },
    { state: 'Kebbi', lgas: ['Aleiro', 'Arewa Dandi', 'Argungu', 'Augie', 'Bagudo', 'Birnin Kebbi', 'Bunza', 'Dandi', 'Fakai', 'Gwandu', 'Jega', 'Kalgo', 'Koko/Besse', 'Maiyama', 'Ngaski', 'Sakaba', 'Shanga', 'Suru', 'Wasagu', 'Yauri', 'Zuru'] },
    { state: 'Kano', lgas: ['Fagge', 'Dala', 'Gwale', 'Kano Municipal', 'Tarauni', 'Nassarawa', 'Kumbotso', 'Ungogo', 'Dawakin Tofa', 'Tofa', 'Rimin Gado', 'Bagwai', 'Gezawa', 'Gabasawa', 'Minjibir', 'Dambatta', 'Makoda', 'Kunchi', 'Bichi', 'Tsanyawa', 'Shanono', 'Gwarzo', 'Karaye', 'Rogo', 'Kabo', 'Bunkure', 'Kibiya', 'Rano', 'Tudun Wada', 'Doguwa', 'Madobi', 'Kura', 'Garun Mallam', 'Bebeji', 'Kiru', 'Sumaila', 'Garko', 'Takai', 'Albasu', 'Gaya', 'Ajingi', 'Wudil', 'Warawa', 'Dawakin Kudu'] },
    { state: 'Kogi', lgas: ['Adavi', 'Ajaokuta', 'Ankpa', 'Bassa', 'Dekina', 'Ibaji', 'Idah', 'Igalamela-Odolu', 'Ijumu', 'Kabba/Bunu', 'Koton Karfe', 'Lokoja', 'Mopa-Muro', 'Ofu', 'Ogori/Magongo', 'Okehi', 'Okene', 'Olamaboro', 'Omala', 'Yagba East', 'Yagba West'] },
    { state: 'Kwara', lgas: ['Asa', 'Baruten', 'Edu', 'Ekiti', 'Ifelodun', 'Ilorin East', 'Ilorin South', 'Ilorin West', 'Irepodun', 'Isin', 'Kaiama', 'Moro', 'Offa', 'Oke Ero', 'Oyun', 'Pategi'] },
    { state: 'Nasarawa', lgas: ['Karu', 'Keffi', 'Kokona', 'Nasarawa', 'Toto', 'Akwanga', 'Eggon', 'Wamba', 'Awe', 'Doma', 'Keana', 'Lafia', 'Obi'] },
    { state: 'Ogun', lgas: ['Abeokuta North Akọmọjẹ', 'Abeokuta South Ake', 'Ado-Odo/Ota Otta', 'Ewekoro Itori', 'Ifo Ifo', 'Ijebu East Ọgbẹrẹ', 'Ijebu North Ìjẹ̀bú igbó', 'Ijebu North East Attan', 'Ijebu Ode Ìjèbú ode', 'Ikenne Ikenne', 'Imeko Afon Imẹkọ', 'Ipokia Ipokia', 'Obafemi Owode Owode ègbá', 'Odogbolu Odogbolu', 'Odeda Odẹda', 'Ogun Waterside Abigi', 'Remo North Iṣara rẹmọ', 'Sagamu', '(Shagamu) Sagamu', 'Yewa North', '(formerly Egbado North) Ayetoro', 'Yewa South', '(formerly Egbado South) Ilaro]'] },
    { state: 'Ondo', lgas: ['Akoko North-East (headquarters in Ikare)', 'Akoko North-West (headquarters in Okeagbe)', 'Akoko South-East (headquarters in Isua)', 'Akoko South-West (headquarters in Oka)', 'Akure North (headquarters in Iju / Itaogbolu)', 'Akure South (headquarters in Akure)', 'Ese Odo (headquarters in Igbekebo)', 'Idanre (headquarters in Owena)', 'Ifedore (headquarters in Igbara Oke)', 'Ilaje (headquarters in Igbokoda)', 'Ile Oluji/Okeigbo (headquarters in Ile Oluji)', 'Irele (headquarters Ode-Irele)', 'Odigbo (headquarters in Ore)', 'Okitipupa (headquarters in Okitipupa)', 'Ondo East (headquarters in Bolorunduro)', 'Ondo West (headquarters Ondo Town)', 'Ose (headquarters in Ifon)', 'Owo (headquarters in Owo Town)'] },
    { state: 'Osun', lgas: ['Aiyedaade', 'Aiyedire', 'Atakunmosa East', 'Atakunmosa West', 'Boluwaduro', 'Boripe', 'Ede North', 'Ede South', 'Egbedore', 'Ejigbo', 'Ife Central', 'Ife East', 'Ife North', 'Ife South', 'Ifedayo', 'Ifelodun', 'Ila', 'Ilesa East', 'Ilesa West', 'Irepodun', 'Irewole', 'Isokan', 'Iwo', 'Obokun', 'Odo Otin', 'Ola Oluwa', 'Olorunda', 'Oriade', 'Orolu', 'Osogbo'] },
    { state: 'Oyo', lgas: ['Ibarapa Central Igbo Ọra', 'Ibarapa East Eruwa', 'Ibarapa North Ayete', 'Ido Ido', 'Irepo Kisi-Okeogun', 'Isẹyin Isẹyin-Okeogun', 'Itẹsiwaju Otu-Okeogun', 'Iwajọwa Iwereile-Okeogun', 'Kajọla Okeho-Okeogun', 'Lagelu Iyanaofa', 'Ogbomọṣọ North Ogbomọṣọ', 'Ogbomọṣọ South Arowomọle', 'Ogo Oluwa Ajawa', 'Olorunṣogo Igbẹti-Okeogun', 'Oluyọle Idi Ayunrẹ', 'Ọna Ara Akanran', 'Orelope Igboho-Okeogun', 'Ori Ire Ikoyi', 'Ọyọ East Kosobo', 'Ọyọ West Ojongbodu', 'Ṣaki East Agoamodu-Okeogun', 'Ṣaki West Shaki-Okeogun', 'Surulere Iresa Adu', 'Afijio Jobele', 'Akinyẹle Moniya', 'Atiba Ọfa Meta', 'Atisbo Tede-Okeogun', 'Ẹgbeda Ẹgbeda', 'Ibadan North Agodi Gate', 'Ibadan North-East Iwo Road', 'Ibadan North-West Dugbẹ/Onireke', 'Ibadan South-East Mapo', 'Ibadan South-West Ring Road'] },
    { state: 'Plateau', lgas: ['Barkin Ladi', 'Bassa', 'Bokkos', 'Jos East', 'Jos North', 'Jos South', 'Kanam', 'Kanke', 'Langtang North', 'Langtang South', 'Mangu', 'Mikang', 'Pankshin', 'Qua\'an Pan', 'Riyom', 'Shendam', 'Wase'] },
    { state: 'Sokoto', lgas: ['Binji', 'Bodinga', 'Dange Shuni', 'Gada', 'Goronyo', 'Gudu', 'Gwadabawa', 'Illela', 'Isa', 'Kebbe', 'Kware', 'Rabah', 'Sabon Birni', 'Shagari', 'Silame', 'Sokoto North', 'Sokoto South', 'Tambuwal', 'Tangaza', 'Tureta', 'Wamako', 'Wurno', 'Yabo'] },
    { state: 'Taraba', lgas: ['Ardo Kola', 'Bali', 'Donga', 'Gashaka', 'Gassol', 'Ibi', 'Jalingo', 'Karim Lamido', 'Kurmi', 'Lau', 'Sardauna', 'Takum', 'Ussa', 'Wukari', 'Yorro', 'Zing'] },
    { state: 'Yobe', lgas: ['Bade', 'Bursari', 'Damaturu', 'Geidam', 'Gujba', 'Gulani', 'Fika', 'Fune', 'Jakusko', 'Karasuwa', 'Machina', 'Nangere', 'Nguru', 'Potiskum', 'Tarmuwa', 'Yunusari', 'Yusufari'] },
    { state: 'Zamfara', lgas: ['Anka', 'Bakura', 'Birnin Magaji/Kiyaw', 'Bukkuyum', 'Bungudu', 'Tsafe', 'Gummi', 'Gusau', 'Kaura Namoda', 'Maradun', 'Maru', 'Shinkafi', 'Talata Mafara', 'Zurmi'] }

]
