<template>
    <div
        @click="$emit('plan-code', plan)"
        :data-plan-code="plan.code"
        class="border border-border-color rounded-2xl text-center cursor-pointer"
    >
        <div class="text-xl py-5 border-b border-border-color capitalize" v-html="plan.name"></div>
        <div class="my-5">
            <div class="text-sm leading-loose">Installation Fee</div>

            <div class="text-2xl text-gray-400 line-through">
                N137,062.50
            </div>
            <div>
                <span class="text-2xl text-primary-color-orange"
                    >N{{ plan.installationFee | moneyFormat }}</span
                >
                <span class="text-xs"> ( VAT inclusive )</span>
            </div>
        </div>
        <div class="my-5">
            <div class="text-sm leading-loose">Monthly Recurring Fee</div>
            <div class="text-2xl text-primary-color-orange">
                N{{ plan.monthlyReoccuringFee | moneyFormat }}
            </div>
        </div>
        <div v-html="plan.description" class="my-5 text-xs lg:px-4 xl:px-4 px-6"></div>
    </div>
</template>

<script>
export default {
    props: {
        plan: {
            type: Object,
            default: null,
        },
    },
    filters: {
        moneyFormat: function numberWithCommas(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
    },
}
</script>

<style></style>
