<template>
    <main
        class="w-screen lg:h-screen xl:h-screen h-auto box-border lg:fixed xl:fixed relative top-0"
    >
        <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
            <template v-slot:error-message>
                <span class="pl-2 text-md">{{ errorMessage }}</span>
            </template>
        </error-toast>
        <NotificationToast
            v-if="notification"
            :notification="notification"
            @resetNotification="notification = null"
        />
        <header
            class="h-1/5 lg:w-3/4 xl:3/4 w-full lg:px-0 xl:px-0 px-7 mx-auto pt-11 pb-4 flex items-end justify-between border-b border-border-color bg-white sticky top-0 z-40"
        >
            <div>
                <router-link to="/">
                    <img
                        src="@/assets/images/legend-logo.svg"
                        alt="site logo"
                        class="lg:w-full xl:w-full w-3/4"
                    />
                </router-link>
                <div class="mt-6 lg:text-base xl:text-base text-sm">
                    Fill out this form to order your Legend Fiber Service
                </div>
            </div>
            <!-- <submit-button v-if="isBasicInfo" @next="$router.push({ name: 'login' })">
                <span slot="button-text">Login</span>
            </submit-button> -->
        </header>
        <section
            class="lg:h-4/5 xl:h-4/5 h-auto lg:w-3/4 xl:w-3/4 w-full mx-auto flex lg:flex-row xl:flex-row flex-col"
        >
            <aside
                class="lg:w-1/4 xl:1/4 h-full lg:border-r xl:border-r border-r-0 border-border-color sticky bg-white pt-3 z-40 lg:mx-0 xl:mx-0 mx-7"
                style="top: 8.15rem"
            >
                <div
                    class="lg:mr-16 xl:mr-16 mr-0 lg:mt-8 xl:mt-8 mt-12 lg:pb-0 xl:pb-0 pb-4 lg:border-r xl:border-r lg:border-t-0 xl:border-t-0 border-t border-r-0 border-border-color"
                >
                    <nav-item></nav-item>
                </div>
            </aside>
            <div
                class="lg:w-4/5 xl:w-4/5 w-full pt-10 pb-20 lg:pl-12 xl:pl-12 pl-0 overflow-y-scroll"
            >
                <BasicInfo
                    v-if="isBasicInfo"
                    :submitting="submitting"
                    @next="collectAddress"
                    @error-message="displayError"
                />
                <AddressVerification
                    v-if="isAddress"
                    @emit="captureEvent"
                    @next="coverageCheckResult"
                    @error-message="displayError"
                    @service-requested="serviceRequestComplete = true"
                />
                <ChoseProduct
                    v-if="isProducts"
                    @back="backToAddress"
                    @next="makePayment"
                    @error-message="errorMessage = 'You must select plan.'"
                    @send-invoice="sendInvoice"
                />
                <MakePayment
                    v-if="isPayment"
                    @back="backToPlans"
                    @cancel-payment="cancelPayment"
                    @termsAndConditions="toggleTermsConditions"
                />
            </div>
        </section>
        <custom-modal v-if="serviceRequestComplete" @further-action="backToHome">
            <lottie-animation
                slot="lottie"
                path="lottieFiles/send-email.json"
                :width="255"
                :height="255"
            />
            <div slot="message" class="w-3/4 mx-auto mt-12 text-lg font-medium">
                Congratulations, your request has been sent to sales and business team, you will
                hear from us via email or phone within 24 hrs.
            </div>
            <span slot="text">return home</span>
        </custom-modal>
        <custom-modal v-if="coverageAvailable" @further-action="selectProduct">
            <section slot="message">
                <MapView />
                <div
                    slot="message"
                    class="xl:w-3/4 lg:w-3/4 md:w-3/4 w-11/2 mx-auto mt-12 text-lg font-medium"
                >
                    <h1 class="text-center text-2xl font-bold tracking-wider">Great news!</h1>
                    <p>
                        Legend is in your area. Upon processing your information, our installation
                        process will take approximately 2 to 4 weeks depending on demand.
                    </p>
                </div>
            </section>
            <span slot="text">next step</span>
        </custom-modal>
        <custom-modal v-if="coverageUnavailable" @further-action="backToHome">
            <lottie-animation
                slot="lottie"
                path="lottieFiles/sad-search.json"
                :width="250"
                :height="250"
            />
            <div slot="message" class="w-3/5 mx-auto mt-12 text-lg font-medium">
                We presently do not have coverage in this area. We have received several requests in
                your area and we will inform you when we are in your area.
            </div>
            <span slot="text">return home</span>
        </custom-modal>
        <section
            v-if="showTermsAndConditions"
            class="w-screen h-screen overflow-y-scroll flex items-center justify-center fixed right-0 top-0 bg-black bg-opacity-40 z-50"
        >
            <TermsConditions @termsAndConditions="toggleTermsConditions" />
        </section>
    </main>
</template>

<script>
import NavItem from '@/components/SideNavItem.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import AddressVerification from '@/components/AddressVerification.vue'
import ChoseProduct from '@/components/Products.vue'
import MakePayment from '@/components/Payment.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import CustomModal from '@/components/CustomModal.vue'
import MapView from '@/components/MapView.vue'
import TermsConditions from '@/components/TermsCondition.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'HomePage',
    components: {
        NavItem,
        BasicInfo,
        AddressVerification,
        ChoseProduct,
        MakePayment,
        LottieAnimation,
        CustomModal,
        MapView,
        TermsConditions,
    },
    data() {
        return {
            isBasicInfo: true,
            isAddress: false,
            isProducts: false,
            isPayment: false,
            serviceRequestComplete: false,
            coverageAvailable: false,
            coverageUnavailable: false,
            errorMessage: null,
            notification: null,
            submitting: false,
            surveyReport: null,
            showTermsAndConditions: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const userData = vm.$route.query
            if (Object.keys(userData).length) {
                const user = {
                    id: userData.ref,
                    first_name: userData.name.split(' ')[0],
                    last_name: userData.name.split(' ')[1],
                    email: userData.email,
                    amount: Number(userData.installation_fee) + Number(userData.subscription_fee),
                    phone_number: userData.phone,
                }
                vm.$store.commit('UPDATE_CURRENT_USER', user)
                vm.makePayment()
            }
        })
    },
    computed: {
        ...mapGetters(['currentUser']),
        selectedPlan() {
            return JSON.parse(localStorage.getItem('selected-plan'))
        },
    },
    watch: {
        isBasicInfo() {
            if (this.isBasicInfo) {
                const elements = this.selectDOMNode('basic info')
                elements.forEach(element => this.setBackground(element))
                this.removeBackground('basic info')
            }
        },
        isAddress() {
            if (this.isAddress) {
                const elements = this.selectDOMNode('address')
                elements.forEach(element => this.setBackground(element))
                this.removeBackground('address')
            }
        },
        isProducts() {
            if (this.isProducts) {
                const elements = this.selectDOMNode('products')
                elements.forEach(element => this.setBackground(element))
                this.removeBackground('products')
            }
        },
        isPayment() {
            if (this.isPayment) {
                const elements = this.selectDOMNode('payment')
                elements.forEach(element => this.setBackground(element))
                this.removeBackground('payment')
            }
        },
    },
    mounted() {
        const elements = this.selectDOMNode('basic info')
        elements.forEach(element => this.setBackground(element))

        this.checkSelectedPlan()
    },
    methods: {
        toggleTermsConditions(value) {
            this.showTermsAndConditions = value
        },
        checkSelectedPlan() {
            if (this.selectedPlan) {
                this.makePayment()
            }
        },
        cancelPayment() {
            this.notification = {
                type: 'info',
                message: 'Transaction cancelled',
            }
        },
        makePayment() {
            this.isBasicInfo = false
            this.isProducts = false
            this.isPayment = true
            this.isAddress = false
        },
        backToPlans() {
            this.isBasicInfo = false
            this.isProducts = true
            this.isPayment = false
            this.isAddress = false
        },
        backToAddress() {
            this.isBasicInfo = false
            this.isProducts = false
            this.isPayment = false
            this.isAddress = true
        },
        async collectAddress(userData) {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                const { status, data } = await this.axios.post('/lead/store/step/1', userData)

                if (status >= 200 && status < 300) {
                    this.$store.commit('UPDATE_CURRENT_USER', data.data)
                    localStorage.setItem('current-user', JSON.stringify(data.data))
                    this.isBasicInfo = false
                    this.isProducts = false
                    this.isPayment = false
                    this.isAddress = true
                    this.submitting = false
                }
            } catch (error) {
                this.submitting = false

                if (error.response.status === 422) {
                    const [message] = error.response.data.errors.email
                    this.errorMessage = message
                } else {
                    this.errorMessage = error.response.data.message
                }
            }
        },
        coverageCheckResult(value) {
            const type = typeof value

            if (type.toLowerCase() === 'object') {
                this.surveyReport = value
                this.coverageAvailable = true
            } else {
                this.coverageUnavailable = true
            }
        },
        selectProduct() {
            this.isBasicInfo = false
            this.isProducts = true
            this.isPayment = false
            this.isAddress = false
            this.coverageAvailable = false
        },
        captureEvent(target) {
            if (Object.keys(target.dataset).includes('back')) {
                this.isBasicInfo = true
                this.isAddress = false
            }
        },
        selectDOMNode(dataAttribute) {
            const nodes = document.querySelectorAll('[data-user-data]')
            const targetNode = []
            nodes.forEach(node => {
                if (node.dataset.userData.toLowerCase() === dataAttribute) {
                    targetNode.push(node)
                }
            })

            return targetNode
        },
        setBackground(selector) {
            selector.style.backgroundColor = '#FF5200'
        },
        removeBackground(dataAttribute) {
            const nodes = document.querySelectorAll('[data-user-data]')
            nodes.forEach(node => {
                if (node.dataset.userData.toLowerCase() !== dataAttribute) {
                    node.style.backgroundColor = '#8E9193'
                }
            })
        },
        backToHome() {
            this.serviceRequestComplete = false
            this.coverageUnavailable = false
            this.isBasicInfo = true
            this.isProducts = false
            this.isPayment = false
            this.isAddress = false
        },
        displayError(message) {
            this.errorMessage = message
        },
        async sendInvoice(plan) {
            try {
                if (Object.keys(this.$route.query).length) {
                    return
                }

                const currentUser = JSON.parse(localStorage.getItem('current-user'))
                const formdata = new FormData()

                formdata.append('user_id', currentUser.id)
                formdata.append('installation_fee', plan.installationFee)
                formdata.append('subscription_fee', plan.monthlyReoccuringFee)
                formdata.append('product_name', plan.code)

                const { status, data } = await this.axios.post('/lead/sendInvoice', formdata)

                if (status === 200) {
                    this.notification = {
                        type: 'success',
                        message: data.message,
                    }
                }
            } catch (error) {
                console.log(error)
                this.notification = {
                    type: 'error',
                    message: error.response.data.message,
                }
            }
        },
    },
}
</script>
