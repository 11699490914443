<template>
    <div class="flex flex-row-reverse">
        <button
            @click.prevent="$emit('next')"
            class="capitalize text-white bg-primary-color-orange rounded-lg py-3 px-10 lg:w-auto xl:w-auto w-full border-2 border-bg-primary-color-orange"
            :class="{ 'inactive-state': submitting }"
        >
            <slot name="button-text" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'NextButton',
    props: {
        submitting: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
