import Vue from 'vue'
import VueClosable from 'vue-closable'
import Flutterwave from 'flutterwave-vue-v3'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import SuccessToast from '@/components/notificationToasts/SuccessToast.vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosInstance from './services/axios'
import '@/assets/css/tailwind.css'
import '@/assets/css/main.css'

// Global component registeration
Vue.component('NotificationToast', () => /* webpackChunkName: 'toast' */import('@/components/notificationToasts/NotificationToast.vue'))

Vue.config.productionTip = false

Vue.use(VueClosable)
Vue.use(require('vue-moment'))

Vue.use(Flutterwave, { publicKey: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY })

Vue.component('ErrorToast', ErrorToast)
Vue.component('SuccessToast', SuccessToast)

Vue.prototype.axios = axiosInstance

new Vue({
  created() {
    const auth = JSON.parse(localStorage.getItem('sale_autousr_data'))

    if (auth) {
      this.$store.commit('UPDATE_CURRENT_USER', auth)
    }
  },
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
