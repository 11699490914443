<template>
    <main class="relative">
        <div>
            <label class="text-sm leading-loose">{{ dataField.label }}</label>
            <input
                v-model="inputValue"
                type="text"
                readonly
                :placeholder="dataField.placeholder"
                class="border border-border-color rounded-lg p-3 block w-full focus:outline-none focus:border-primary-color-orange cursor-pointer capitalize"
                @focus="showDropdown"
            />
            <svg
                class="absolute right-5 bottom-5"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.62117 3.93568L0.827484 0.142022C0.638179 -0.0472832 0.331265 -0.0472832 0.141961 0.142022C-0.0473205 0.331326 -0.0473205 0.63824 0.141961 0.827544L4.2784 4.96398C4.4677 5.15328 4.77462 5.15328 4.96392 4.96398L9.10036 0.827544C9.19502 0.732903 9.24234 0.608825 9.24234 0.48477C9.24234 0.360716 9.19502 0.236638 9.10036 0.141997C8.91105 -0.0473069 8.60414 -0.0473069 8.41484 0.141997L4.62117 3.93568Z"
                    fill="#4B5054"
                />
            </svg>
        </div>
        <div
            :data-label="dataField.field"
            class="w-full absolute top-full left-0 z-30 bg-white border border-gray-100 rounded shadow capitalize hidden h-48 overflow-y-scroll overscroll-y-contain"
        >
            <ul
                v-if="currentField === 'state'"
                class="text-sm max-h-48 overflow-y-scroll overscroll-y-contain"
            >
                <li
                    v-for="(item, index) in listOfStates"
                    :key="index"
                    class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
                    @click="selectState(item)"
                >
                    {{ item.state }}
                </li>
            </ul>
            <ul
                v-if="currentField === 'city'"
                class="city-option text-sm overflow-y-scroll overscroll-y-contain"
            >
                <li
                    v-for="(city, index) in listOfCities"
                    :key="index"
                    class="py-1 px-2 hover:bg-gray-100 cursor-pointer capitalize"
                    @click="selectCity(city)"
                >
                    {{ city }}
                </li>
            </ul>
            <ul
                v-if="currentField === 'area'"
                class="area-option text-sm overflow-y-scroll overscroll-y-contain"
            >
                <li
                    v-for="(area, index) in listOfAreas"
                    :key="index"
                    class="py-1 px-2 hover:bg-gray-100 cursor-pointer capitalize"
                    @click="selectArea(area)"
                >
                    {{ area }}
                </li>
            </ul>
            <ul v-if="currentField === 'survey'">
                <li
                    v-for="(source, index) in sources"
                    :key="index"
                    class="py-1 px-2 hover:bg-gray-100 cursor-pointer capitalize"
                    @click="selectSurvey(source)"
                >
                    {{ source }}
                </li>
                <li class="py-1 px-2 flex items-center">
                    <div data-other class="mr-3 cursor-pointer flex-shrink-0">
                        Other (Please specify)
                    </div>
                    <input
                        v-model="inputValue"
                        type="text"
                        class="border border-border-color rounded-lg p-2 block w-full focus:outline-none"
                    />
                </li>
            </ul>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import states from '@/util/data'
import abujaAreas from '@/util/areas'

export default {
    name: 'InputField',
    props: {
        dataField: {
            type: Object,
            required: true,
        },
    },
    watch: {
        inputValue() {
            this.$emit('input-value', { value: this.inputValue, field: this.dataField.field })
        },
        globalEvent() {
            const elements = document.querySelectorAll('[data-label]')

            if (
                !this.excludedTags.includes(this.globalEvent.tagName.toLowerCase()) &&
                !Object.keys(this.globalEvent.dataset).includes('other')
            ) {
                elements.forEach(element => {
                    element.classList.add('hidden')
                })
            }
        },
    },
    computed: {
        ...mapGetters(['globalEvent']),
    },
    data() {
        return {
            inputValue: null,
            estatesIncities: null,
            availableEstates: [],
            city: null,
            currentNode: null,
            currentField: null,
            listOfStates: [],
            listOfCities: [],
            errorMessage: null,
            listOfAreas: null,
            excludedTags: ['svg', 'path', 'li', 'input'],
            abujaAreas: abujaAreas,
            sources: [
                'Neighbor/Friend',
                'Legend Staff',
                'Legend Website',
                'Legend Customer',
                'Installer',
                'Installer Van',
                'Search Engine',
                'Social Media',
                'YouTube',
                'Billboard',
            ],
        }
    },
    beforeDestroy() {
        localStorage.removeItem('selected-state')
        localStorage.removeItem('selected-city')
    },
    methods: {
        showDropdown() {
            const nodes = document.querySelectorAll('[data-label]')
            const field = this.dataField
            this.currentField = field.field

            if (this.currentField === 'state') {
                this.fetchtStates()
            }

            if (this.currentField === 'city') {
                this.listOfCities = JSON.parse(localStorage.getItem('cities'))
            }

            if (this.currentField === 'area') {
                this.listOfAreas = this.abujaAreas
            }

            nodes.forEach(node => {
                if (node.dataset.label === field.field) {
                    this.currentNode = node
                    this.currentNode.classList.remove('hidden')
                }
            })
        },
        selectState(item) {
            this.inputValue = item.state
            localStorage.setItem('selected-state', item.state)
            this.currentNode.classList.add('hidden')

            localStorage.setItem('cities', JSON.stringify(item.lgas))
        },
        selectCity(city) {
            this.inputValue = city
            localStorage.setItem('selected-city', city)
            this.currentNode.classList.add('hidden')
        },
        selectArea(area) {
            this.inputValue = area
            localStorage.setItem('selected-area', area)
            this.currentNode.classList.add('hidden')
        },
        selectSurvey(survey) {
            this.inputValue = survey
            this.currentNode.classList.add('hidden')
        },
        async fetchtStates() {
            this.listOfStates = states
        },
    },
}
</script>
