import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '@/views/SetPassword.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/Terms.vue'),
  },
  {
    path: '/payment-successful',
    name: 'payment',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/PaymentSuccessful.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
