<template>
    <div class="w-full lg:px-0 xl:px-0 px-7">
        <NotificationToast
            v-if="notification"
            :notification="notification"
            @resetNotification="notification = null"
        />
        <div>Step 4/4</div>
        <div class="text-2xl font-bold leading-loose mt-2">Order Summary</div>
        <div class="text-primary-color-gray">
            Please review your order summary below, then click the pay now button to proceed to the
            payment page.
        </div>
        <hr class="lg:block xl:block hidden my-4 border-t border-border-color" />
        <section class="mt-10">
            <div class="border border-border-color rounded-2xl">
                <h1 class="text-xl font-light py-5 border-b border-border-color px-11">
                    Order Summary
                </h1>
                <div class="px-11">
                    <div class="text-sm leading-loose mt-6">Product</div>
                    <div
                        class="text-2xl text-primary-color-orange capitalize"
                        v-html="selectedPlan.name"
                    ></div>
                    <div class="lg:w-2/5 xl:w-2/5 w-full mb-4 mt-8 py-3">
                        <div class="flex justify-between items-center">
                            <div>Installation Fee</div>
                            <div>
                                <span class="font-semibold"
                                    >N{{ selectedPlan.installationFee | moneyFormat }}</span
                                >
                                <span class="text-primary-color-orange text-xs">
                                    ( VAT inclusive )</span
                                >
                            </div>
                        </div>
                        <div class="flex justify-between items-center my-7">
                            <div>Subscription Fee</div>
                            <div class="font-semibold">
                                N{{ selectedPlan.monthlyReoccuringFee | moneyFormat }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="text-lg py-5 px-11 border-t border-border-color flex items-center justify-between"
                >
                    <div class="lg:w-2/5 xl:w-2/5 w-full flex justify-between">
                        <span class="capitalize">total</span>
                        <span class="text-primary-color-orange font-extrabold"
                            >N{{ totalAmount | moneyFormat }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="mt-12">
                <div class="mb-10 text-sm font-medium text-right">
                    <p>
                        By clicking "Pay Now" you agree to have read and accepted our
                        <!-- <span
                            class="text-primary-color-orange cursor-pointer"
                            @click="openTermsConditions"
                            >Terms and Conditions</span
                        >. -->
                        <a
                            :href="url"
                            target="_blank"
                            class="text-primary-color-orange cursor-pointer"
                            >Terms and Conditions</a
                        >.
                    </p>
                </div>
                <div class="block lg:flex xl:flex flex-row-reverse">
                    <next-button :submitting="submitting" @next="makePayment">
                        <span slot="button-text">Pay Now</span>
                    </next-button>
                    <BackButton @back="$emit('back')" class="lg:mt-0 xl:mt-0 mt-5" />
                </div>
            </div>
        </section>
        <!-- <div class="hidden">
            <flutterwave-pay-button
                :tx_ref="generateReference()"
                :amount="totalAmount"
                currency="NGN"
                payment_options="card,ussd"
                class="pay-button"
                :customer="{
                    id: user.id,
                    email: user.email,
                    phone_number: user.phone_number,
                    name: `${user.first_name} ${user.last_name}`,
                }"
                :customizations="{
                    title: 'Legend Internet',
                    description: 'Payment for Legend Internet subscription',
                    logo:
                        'https://res.cloudinary.com/greeanbrain/image/upload/v1627900093/suburban-sales-automation-assets/legend-logo.svg',
                }"
                :callback="makePaymentCallback"
                :onclose="closedPaymentModal"
            >
                Click To Pay
            </flutterwave-pay-button>
        </div> -->
        <!-- Paystack button -->
        <div class="hidden">
            <paystack
                :amount="totalAmount * 100"
                :email="user.email"
                :paystackkey="paystackkey"
                :reference="reference"
                :channels="['bank', 'card', 'bank_transfer', 'qr', 'ussd']"
                :callback="callback"
                :close="close"
                :embed="false"
            >
            </paystack>
        </div>
    </div>
</template>

<script>
import NextButton from '@/components/NextButton.vue'
import BackButton from '@/components/BackButton.vue'
import axios from 'axios'
import paystack from 'vue-paystack'

export default {
    name: 'PaymentPage',
    components: {
        NextButton,
        BackButton,
        paystack,
    },
    filters: {
        moneyFormat: function numberWithCommas(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
    },
    computed: {
        selectedPlan() {
            return JSON.parse(localStorage.getItem('selected-plan'))
        },
        totalAmount() {
            return this.selectedPlan.installationFee + this.selectedPlan.monthlyReoccuringFee
        },
        user() {
            if (!this.$store.getters.currentUser) {
                return JSON.parse(localStorage.getItem('current-user'))
            }

            return this.$store.getters.currentUser
        },
        termsRoute() {
            return this.$router.resolve({ name: 'terms' })
        },
        reference() {
            // generate transaction reference for paystack
            let text = ''
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

            for (let i = 0; i < 10; i += 1) {
                text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            return text
        },
    },
    data() {
        return {
            errorMessage: null,
            taxRate: 7.5,
            submitting: false,
            notification: null,
            url: `${window.location.href}terms-and-conditions`,
            paystackkey: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
        }
    },
    methods: {
        callback(response) {
            this.savePayment({
                client: `${this.user.first_name} ${this.user.last_name}`,
                amount: this.totalAmount,
                notification_message: response.message,
                transaction_reference: response.reference,
                product_name: this.selectedPlan.code,
                status: response.status,
                user_id: this.user.id,
            })
        },
        close() {
            console.log('Payment closed')
        },
        openTermsConditions() {
            this.$emit('termsAndConditions', true)
        },
        // makePaymentCallback(response) {
        //     this.closePaymentModal()

        //     this.savePayment({
        //         client: `${this.user.first_name} ${this.user.last_name}`,
        //         amount: response.amount,
        //         notification_message: response.status,
        //         transaction_reference: response.tx_ref,
        //         product_name: this.selectedPlan.code,
        //         status: response.status,
        //         user_id: this.user.id,
        //     })

        //     this.$router.push({ name: 'payment' })
        // },
        closedPaymentModal() {
            this.submitting = false
            this.$emit('cancel-payment')
        },
        generateReference() {
            const date = new Date()
            return date.getTime().toString()
        },
        makePayment() {
            if (this.submitting) {
                return
            }

            this.submitting = true

            // const node = document.querySelector('.pay-button')
            const node = document.querySelector('.payButton')
            node.click()
        },
        async savePayment(payload) {
            try {
                const { status, data } = await this.axios.post('customer/fund/paystack', payload)
                if (status >= 200 && status < 300) {
                    this.clearLocalStorage('selected-plan')
                    this.clearLocalStorage('current-user')
                    this.clearLocalStorage('cities')
                    this.$store.commit('CLEAR_STORE')
                    this.$router.push({ name: 'payment' })
                    this.createUserOnSrun({
                        user_real_name: data.data.user_real_name,
                        email_address: data.data.email_address,
                        username: data.data.username,
                    })
                }
            } catch (error) {
                this.submitting = false
                this.notification = {
                    type: 'error',
                    message: error.response.data.message,
                }
            }
        },
        async createUserOnSrun(payload) {
            try {
                const { status, data } = await axios.post(
                    'http://developapi.legendpay.ng:8050/api/create-sales-automation-users-on-srun',
                    payload
                )

                if (status >= 200 && status < 300) {
                    this.notification = {
                        type: 'success',
                        message: `
                        <p class="capitalize"><strong>Congratulations ${data.data.user_real_name}</strong></p>
                        <p>Welcome to legend</p>
                        `,
                    }
                }
            } catch (error) {
                this.submitting = false
                this.notification = {
                    type: 'error',
                    message: error.response.data.message,
                }
            }
        },
        clearLocalStorage(value) {
            localStorage.removeItem(value)
        },
    },
}
</script>
