<template>
  <main v-if="successMessage" class="fixed top-44 right-6 w-80 h-14 border border-green-500 bg-successToastBg rounded-md flex items-center justify-start">
      <img src="@/assets/images/success-toast-icon.svg" alt="budget created successfully" class="w-12">
      <slot name="success-message"></slot>
  </main>
</template>

<script>
export default {
    props: ['successMessage'],
    watch: {
        successMessage() {
            if (this.successMessage) {
                setTimeout(() => {
                    this.$emit('resetSucessMessage')
                }, 4000)
            }
        }
    }
}
</script>
