<template>
    <div class="w-full lg:px-0 xl:px-0 px-7 relative" @click="emitEvent">
        <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
            <template v-slot:error-message>
                <span class="pl-2 text-md">{{ errorMessage }}</span>
            </template>
        </error-toast>
        <div>Step 2/4</div>
        <div class="text-2xl font-bold leading-loose mt-2">Let’s start with your address</div>
        <div class="text-primary-color-gray">
            Please select where you will be using this product and enter your address
        </div>
        <hr class="lg:block xl:block hidden my-4 border-t border-border-color" />
        <form class="mt-6">
            <div class="grid grid-cols-2 gap-10 my-10">
                <div
                    @click="toggleHome"
                    data-home
                    class="flex flex-col items-center justify-center border border-primary-color-orange rounded-2xl py-7 cursor-pointer"
                >
                    <img v-if="!isHome" src="@/assets/images/home-icon-inactive.svg" alt="home" />
                    <img v-else src="@/assets/images/home-icon-active.svg" alt="home" />
                    <div class="capitalize mt-3">home</div>
                </div>
                <div
                    @click="toggleOffice"
                    data-office
                    class="flex flex-col items-center justify-center rounded-2xl py-7 cursor-pointer border border-border-color"
                >
                    <img
                        v-if="!isOffice"
                        src="@/assets/images/office-icon-inactive.svg"
                        alt="home"
                    />
                    <img v-else src="@/assets/images/office-icon-active.svg" alt="home" />
                    <div class="capitalize mt-3">office</div>
                </div>
            </div>
            <div v-if="isHome">
                <div class="my-12">
                    <h1 class="leading-loose">Do you live in an estate?</h1>
                    <div class="flex mt-2">
                        <div class="mr-8 flex items-center">
                            <img
                                v-if="picked.toLowerCase() === 'yes'"
                                src="@/assets/images/checked-radio.svg"
                                alt="Do you live in an estate?"
                                class="mr-2 cursor-pointer"
                                @click="picked = 'no'"
                            />
                            <input
                                v-else
                                type="radio"
                                value="yes"
                                v-model="picked"
                                class="mr-2 w-4"
                            />
                            <label>Yes</label>
                        </div>
                        <div class="mr-8 flex items-center">
                            <img
                                v-if="picked.toLowerCase() === 'no'"
                                src="@/assets/images/checked-radio.svg"
                                alt="Do you live in an estate?"
                                class="mr-2 cursor-pointer"
                                @click="picked = 'yes'"
                            />
                            <input
                                v-else
                                type="radio"
                                value="no"
                                v-model="picked"
                                class="mr-2 w-4"
                            />
                            <label>No</label>
                        </div>
                    </div>
                </div>
                <div
                    class="grid lg:grid-cols-2 xl:grid-cols-2 grid-cols-1 lg:gap-10 xl:gap-10 gap-0 lg:mb-6 xl:mb-6 mb-0"
                >
                    <custom-select
                        @input-value="assignValue"
                        :dataField="{
                            label: 'State',
                            field: 'state',
                            placeholder: 'Select state',
                        }"
                        class="lg:mb-0 xl:mb-0 mb-6"
                    ></custom-select>
                    <custom-select
                        @input-value="assignValue"
                        :dataField="{ label: 'LGA', field: 'city', placeholder: 'Select LGA' }"
                        class="lg:mb-0 xl:mb-0 mb-6"
                    ></custom-select>
                </div>
                <div v-if="picked.toLowerCase() === 'yes'" class="text-sm mb-6">
                    <label class="leading-loose">Estate</label>
                    <input
                        v-model="estate"
                        type="text"
                        placeholder="type your estate"
                        class="border border-border-color rounded-lg p-3 block w-full focus:outline-none focus:border-primary-color-orange"
                    />
                </div>
                <div
                    class="grid grid-cols-1 lg:gap-10 xl:gap-10 gap-0 lg:mb-6 xl:mb-6 mb-0"
                    :class="{ 'lg:grid-cols-2 xl:grid-cols-2': state.toLowerCase() === 'abuja' }"
                >
                    <custom-select
                        v-if="state.toLowerCase() === 'abuja'"
                        @input-value="assignValue"
                        :dataField="{ label: 'Area', field: 'area', placeholder: 'Select area' }"
                        class="lg:mb-0 xl:mb-0 mb-6"
                    ></custom-select>
                    <custom-input
                        @input-value="assignValue"
                        :dataField="{
                            label: 'House number',
                            field: 'streetNo',
                            placeholder: 'Enter house number',
                        }"
                    ></custom-input>
                </div>
                <custom-input
                    @input-value="assignValue"
                    :dataField="{
                        label: 'Street name',
                        field: 'streetName',
                        placeholder: 'Enter street name',
                    }"
                ></custom-input>
            </div>
            <div v-if="isOffice" class="my-8 text-lg">
                Legend Zone is only available for home. If you are a business - you will be directed
                to our sales team that handle offices survey.
            </div>
            <div class="block lg:flex xl:flex flex-row-reverse items-end lg:mt-14 xl:mt-14 mt-8">
                <next-button :submitting="submitting" @next="nextStep">
                    <span slot="button-text">{{
                        isHome ? 'Check Coverage' : 'Request Service'
                    }}</span>
                </next-button>
                <BackButton class="lg:mt-0 xl:mt-0 mt-5" />
            </div>
        </form>
        <section
            v-if="fullAddress"
            class="w-screen h-screen flex items-center justify-center fixed right-0 top-0 z-50"
            style="background-color: rgba(0, 0, 0, 0.4)"
        >
            <div
                class="lg:w-1/3 xl:w-1/3 w-11/12 h-80 bg-white rounded-lg p-12 flex flex-col justify-between"
            >
                <h1 class="text-xl font-bold">
                    Please confirm your address blow:
                </h1>
                <div class="text-2xl">{{ fullAddress }}</div>
                <div class="flex justify-between">
                    <button
                        class="capitalize text-sm rounded-lg py-2 px-6 border border-primary-color-orange text-primary-color-orange"
                        :class="{ 'inactive-state': submitting }"
                        @click="incorrectAddress"
                    >
                        Incorrect
                    </button>
                    <button
                        class="capitalize text-white text-sm bg-primary-color-orange border border-primary-color-orange rounded-lg py-2 px-6"
                        :class="{ 'inactive-state': submitting }"
                        @click="checkAvailability"
                    >
                        correct
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import CustomInput from '@/components/InputField.vue'
import CustomSelect from '@/components/InputSelect.vue'
import NextButton from '@/components/NextButton.vue'
import BackButton from '@/components/BackButton.vue'

export default {
    name: 'AddressVerification',
    components: {
        CustomInput,
        NextButton,
        BackButton,
        CustomSelect,
    },
    data() {
        return {
            isHome: true,
            isOffice: false,
            picked: 'no',
            state: '',
            city: null,
            area: null,
            estate: null,
            streetName: null,
            streetNo: null,
            fullAddress: null,
            location: null,
            errorMessage: null,
            submitting: false,
        }
    },
    computed: {
        userId() {
            return this.$store.getters.currentUser.id
        },
    },
    methods: {
        incorrectAddress() {
            if (this.submitting) {
                return
            }

            this.fullAddress = null
        },
        assignValue(value) {
            const currentField = value.field
            this[currentField] = value.value
        },
        toggleHome() {
            this.isHome = true
            this.isOffice = false
            document.querySelector('[data-home]').style.borderColor = '#FF5200'
            this.selectDOMNonde('[data-office]').style.borderColor = '#4B5054'
        },
        toggleOffice() {
            this.isHome = false
            this.isOffice = true
            this.selectDOMNonde('[data-office]').style.borderColor = '#FF5200'
            this.selectDOMNonde('[data-home]').style.borderColor = '#4B5054'
        },
        selectDOMNonde(element) {
            return document.querySelector(element)
        },
        emitEvent(event) {
            this.$emit('emit', event.target)
        },
        async checkAvailability() {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                let coverageAvailable = false

                const { status, data } = await this.axios.post('/lead/store/step/2', {
                    is_office: this.isOffice,
                    estate: this.estate,
                    street_name: this.streetName,
                    state: this.state,
                    city: this.city,
                    area: this.area,
                    street_number: this.streetNo,
                    user_id: this.userId,
                    longitude: this.location.lng,
                    latitude: this.location.lat,
                })

                this.submitting = false

                if (status >= 200 && status < 300 && typeof data.data.results === 'object') {
                    coverageAvailable = true
                    this.$store.commit('SET_LEGEND_PLAN', data.data)
                    this.$store.commit('SET_NODE_LOCATION', data.data.results.results[0])
                    this.$emit('next', data.data.results)
                } else if (status >= 200 && status < 300 && typeof data.data.results === 'string') {
                    coverageAvailable = false
                    this.$emit('next', data.data.results)
                }

                this.saveCoverageReport({
                    user_id: this.userId,
                    is_have_coverage: coverageAvailable ? '1' : '0',
                    longitude: this.location.lng,
                    latitude: this.location.lat,
                })
            } catch (error) {
                this.errorMessage = error.response.data.message
            }
        },
        async saveCoverageReport(payload) {
            await this.axios.post('/lead/update-coverage', payload)
        },
        async makeServiceRequest() {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                const { status } = await this.axios.post('/office/customer/notification', {
                    user_id: this.userId,
                })

                if (status >= 200 && status < 300) {
                    this.submitting = false
                    this.$emit('service-requested')
                }
            } catch (error) {
                this.submitting = false
                this.errorMessage = error.response.data.message
            }
        },
        nextStep() {
            if (this.isOffice) {
                this.makeServiceRequest()
            } else if (this.isHome) {
                this.getGeoLocation()
            }
        },
        async getGeoLocation() {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                if (
                    !this.state ||
                    !this.city ||
                    (this.picked === 'yes' && !this.estate) ||
                    !this.streetName ||
                    !this.streetNo ||
                    (this.state.toLowerCase() === 'abuja' && !this.area)
                ) {
                    this.$emit('error-message', 'All fields are required.')
                    this.submitting = false
                    return
                }

                const { data } = await this.axios.get(
                    'https://maps.googleapis.com/maps/api/geocode/json',
                    {
                        params: {
                            address: `${this.streetNo} ${this.streetName}, ${
                                this.area ? this.area : this.city
                            }, ${this.state}`,
                            key: process.env.VUE_APP_GEOLOCATION_API_KEY,
                        },
                    }
                )

                if (data.results.length) {
                    this.fullAddress = data.results[0].formatted_address
                    this.location = data.results[0].geometry.location
                    this.$store.commit('SET_CUSTOMER_LOCATION', this.location)
                } else {
                    this.errorMessage = 'This address does not exist.'
                }

                this.submitting = false
            } catch (error) {
                this.submitting = false
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
