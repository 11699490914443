<template>
    <main
        v-if="errorMessage"
        class="fixed top-40 right-14 w-80 h-16 border border-red-600 bg-errorToastBg rounded-md flex items-center justify-start text-lg bg-red-100 z-50"
    >
        <img src="@/assets/images/failure-toast-icon.svg" alt="login failed" class="w-12" />
        <slot name="error-message"></slot>
    </main>
</template>

<script>
export default {
    props: ['errorMessage'],
    watch: {
        errorMessage() {
            if (this.errorMessage) {
                setTimeout(() => {
                    this.$emit('resetErroMessage')
                }, 4000)
            }
        },
    },
}
</script>
