<template>
    <div class="w-full lg:px-0 xl:px-0 px-7">
        <div>Step 1/4</div>
        <div class="text-2xl font-bold leading-loose mt-2">Basic Info</div>
        <div class="text-primary-color-gray">
            Fill in your details below, let’s get to know you better.
        </div>
        <hr class="my-4 border-t border-border-color lg:block xl:block hidden" />
        <form class="mt-10">
            <div class="w-full">
                <div class="lg:grid xl:grid block grid-cols-2 gap-8">
                    <custom-input
                        @input-value="assignValue"
                        :dataField="{
                            label: 'First name',
                            field: 'firstName',
                            placeholder: 'Enter first name',
                        }"
                    ></custom-input>
                    <custom-input
                        @input-value="assignValue"
                        :dataField="{
                            label: 'Last name',
                            field: 'lastName',
                            placeholder: 'Enter last name',
                        }"
                    ></custom-input>
                    <custom-input
                        @input-value="assignValue"
                        :dataField="{
                            label: 'Phone number',
                            field: 'phone',
                            placeholder: 'Enter phone number',
                        }"
                    ></custom-input>
                    <custom-input
                        @input-value="assignValue"
                        :dataField="{
                            label: 'Email address',
                            field: 'email',
                            placeholder: 'Enter email address',
                        }"
                    ></custom-input>
                </div>
                <custom-select
                    @input-value="assignValue"
                    :dataField="{
                        label: 'How did you hear about us?',
                        field: 'survey',
                        placeholder: 'Select an option',
                    }"
                    class="my-8"
                ></custom-select>
                <div class="my-8 flex items-center">
                    <input v-if="!checked" v-model="checked" type="checkbox" class="w-4 mr-2" />
                    <img
                        v-else
                        src="@/assets/images/checked-box.svg"
                        alt="checkbox"
                        class="w-4 mr-2"
                        @click="checked = false"
                    />
                    <span class="text-sm">Send me marketing and promotional content.</span>
                </div>
            </div>
            <next-button :submitting="submitting" @next="nextStep">
                <span slot="button-text">Next step</span>
            </next-button>
        </form>
    </div>
</template>

<script>
import CustomInput from '@/components/InputField.vue'
import CustomSelect from '@/components/InputSelect.vue'
import NextButton from '@/components/NextButton.vue'

export default {
    name: 'BasicInfo',
    props: {
        submitting: {
            type: Boolean,
            default: false,
        },
    },
    components: { CustomInput, NextButton, CustomSelect },
    data() {
        return {
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            survey: null,
            checked: false,
        }
    },
    methods: {
        assignValue(value) {
            const currentField = value.field
            this[currentField] = value.value
        },
        nextStep() {
            if (this.email && !this.validateEmail()) {
                this.$emit('error-message', 'Please enter a valid email.')
                return
            }

            if (this.phone && !this.verifyPhoneNumber(this.phone)) {
                this.$emit('error-message', 'Phone number appears to be invalid.')
                return
            }

            if (!this.firstName || !this.lastName) {
                this.$emit('error-message', 'All fields are required.')
                return
            }

            this.$emit('next', {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                phone_number: this.phone,
                how_did_you_here_about_us: this.survey,
                receive_promotion_message: this.checked ? 1 : 0,
            })
        },
        validateEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(
                String(this.email)
                    .toLowerCase()
                    .trim()
            )
        },
        verifyPhoneNumber(number) {
            return number.length > 8 && number.length <= 14
        },
    },
}
</script>
