const state = {
    customerLocation: null,
    nodeLocation: null,
  }

  const getters = {
    customerLocation: (_state) => _state.customerLocation,
    nodeLocation: (_state) => _state.nodeLocation,
  }

  const mutations = {
    SET_CUSTOMER_LOCATION: (_state, data) => {
      _state.customerLocation = data
    },
    SET_NODE_LOCATION: (_state, data) => {
        _state.nodeLocation = data
      },
  }

  export default {
    state,
    getters,
    mutations,
  }
