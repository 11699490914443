const state = {
  globalEvent: null,
  currentUser: null,
  legendPlans: null
}

const getters = {
  globalEvent: (_state) => _state.globalEvent,
  currentUser: (_state) => _state.currentUser,
  legendPlans: (_state) => _state.legendPlans
}

const mutations = {
  SET_GLOBAL_EVENT: (_state, data) => {
    _state.globalEvent = data
  },
  UPDATE_CURRENT_USER: (_state, data) => {
    _state.currentUser = data
  },
  SET_LEGEND_PLAN: (_state, data) => {
    _state.legendPlans = data
  },
  CLEAR_STORE: (_state) => {
    _state.currentUser = null
    _state.legendPlans = null
  }
}

export default {
  state,
  getters,
  mutations,
}
