<template>
    <div class="text-sm lg:mb-0 xl:mb-0 mb-6">
        <label class="leading-loose">{{ dataField.label }}</label>
        <input
            v-model="inputValue"
            type="text"
            :placeholder="dataField.placeholder"
            class="border border-border-color rounded-lg p-3 block w-full focus:outline-none focus:border-primary-color-orange"
        />
    </div>
</template>

<script>
export default {
    name: 'InputField',
    props: {
        dataField: {
            type: Object,
            required: true,
        },
    },
    watch: {
        inputValue() {
            this.$emit('input-value', { value: this.inputValue, field: this.dataField.field })
        },
    },
    data() {
        return {
            inputValue: null,
        }
    },
}
</script>
