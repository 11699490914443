<template>
    <div class="w-full lg:px-0 xl:px-0 px-7">
        <div>Step 3/4</div>
        <div class="text-2xl font-bold leading-loose mt-2">Choose a product</div>
        <div class="text-primary-color-gray">
            To start enjoying unlimited high- speed internet, select the Legend bundle below that
            best fits your needs and budget. For more information and details on what is included in
            each bundle, please visit
            <a
                href="https://www.legend.ng/bundles/"
                target="_blank"
                class="text-primary-color-orange"
                >legend.ng</a
            >
        </div>
        <hr class="lg:block xl:block hidden my-4 border-t border-border-color" />
        <section>
            <div
                class="text-lg flex bg-info-pane-bg border border-info-pane-border rounded-lg p-3 my-10"
            >
                <img
                    src="@/assets/images/info-icon.svg"
                    alt="Installation cost for MDUs has been discounted to N69,000.00 Full installation cost is inclusive of 2 Nodes of Full Home WiFi"
                    class="mr-3.5"
                />
                <span>
                    Installation cost for MDUs has been discounted to <strong>N69,875</strong><br />
                    Full installation cost is inclusive of 2 Nodes of Full Home WiFi
                </span>
            </div>
            <div class="grid lg:grid-cols-3 xl:grid-cols-3 grid-cols- gap-7">
                <PlansCard
                    v-for="(plan, index) in legendPlans"
                    :key="index"
                    :plan="plan"
                    @plan-code="selectPlan(plan)"
                />
            </div>
            <div class="w-4/5 mx-auto flex items-start justify-center py-8">
                <p class="text-center text-sm font-medium">
                    All bundles include free access to hundreds of free Wifi hotspots, access to
                    Legend Pay & Legend Rewards and a Full Home WiFi node.
                </p>
            </div>
        </section>
        <div class="block lg:flex xl:flex flex-row-reverse mt-14">
            <next-button @next="nextStep">
                <span slot="button-text">Next Step</span>
            </next-button>
            <BackButton @back="$emit('back')" class="lg:mt-0 xl:mt-0 mt-5" />
        </div>
    </div>
</template>

<script>
import NextButton from '@/components/NextButton.vue'
import BackButton from '@/components/BackButton.vue'
import PlansCard from '@/components/PlansCard.vue'

export default {
    name: 'ProductsPage',
    components: { NextButton, BackButton, PlansCard },
    computed: {
        plans() {
            return this.$store.getters.legendPlans.installation_fees
        },
    },
    data() {
        return {
            legendPlans: [],
            plansMeta: [
                {
                    code: 'dualPlay',
                    name: 'legend <span class="text-primary-color-orange">dual</span> play',
                    description:
                        '<p>(Unlimited Internet 24hrs, 40Mbps)</p> <p class="mt-1">Added Services: Legend Wifi</p>',
                    subscription: 23550,
                },
                {
                    code: 'triplePlay',
                    name: 'legend <span class="text-primary-color-orange">triple</span> play',
                    description:
                        '<p>(Unlimited Internet 24hrs, 80Mbps)</p> <p class="mt-1">Added Services: Fixed line with 300 Minutes (Both local and international) Legend Wifi</p>',
                    subscription: 36550,
                },
                {
                    code: 'triplePlayX',
                    name: 'legend <span class="text-primary-color-orange">triple</span> play X',
                    description:
                        '<p>(Unlimited Internet 24hrs, 150Mbps)</p> <p class="mt-1">Added Services: Fixed line with 300 Minutes (Both local and international) Legend Wifi</p>',
                    subscription: 73100,
                },
            ],
            selectedPlan: null,
        }
    },
    mounted() {
        this.plansMeta.forEach(plan => {
            this.legendPlans.push({
                code: plan.code,
                name: plan.name,
                installationFee: 69875,
                monthlyReoccuringFee: plan.subscription,
                description: plan.description,
            })
        })
    },
    methods: {
        selectPlan(plan) {
            this.selectedPlan = plan

            const elements = document.querySelectorAll('[data-plan-code]')

            elements.forEach(element => {
                element.classList.add('border-border-color')
            })

            elements.forEach(element => {
                if (element.dataset.planCode.toLowerCase() === plan.code.toLowerCase()) {
                    element.classList.remove('border-border-color')
                    element.classList.add('border-primary-color-orange')
                }
            })
        },
        sendInvoice() {
            this.$emit('send-invoice', this.selectedPlan)
        },
        nextStep() {
            if (!this.selectedPlan) {
                this.$emit('error-message')
                return
            }

            const plan = this.legendPlans.find(tick => tick.code === this.selectedPlan.code)

            this.sendInvoice()

            localStorage.setItem('selected-plan', JSON.stringify(plan))

            this.$emit('next')
        },
    },
}
</script>
