<template>
    <div @click="eventDeligate" id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    methods: {
        eventDeligate(event) {
            this.$store.commit('SET_GLOBAL_EVENT', event.target)
        },
    },
}
</script>

<style>
#app {
    font-family: 'sf-pro', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #262324;
}
</style>
