import Vue from 'vue'
import Vuex from 'vuex'
import globalEvent from './modules/globalEvent'
import location from './modules/locationCoordinates'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    globalEvent, location
  },
})
