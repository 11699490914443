<template>
    <main id="map" ref="map"></main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Maps',
    mounted() {
        if (!window.google) {
            const script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_JAVASCRIPT_API}&libraries=geometry`

            document.body.appendChild(script)
        }

        setTimeout(() => {
            this.addMap()

            const { latitude, longitude } = this.nodeLocation

            this.$nextTick(() => {
                this.calcRoute(this.customerLocation, { lat: latitude, lng: longitude })
            })
        }, 1000)

        setTimeout(() => {
            // const { latitude, longitude } = this.nodeLocation

            this.addMap()
            this.addMarker({
                lat: this.customerLocation.lat,
                lng: this.customerLocation.lng,
                icon:
                    'https://res.cloudinary.com/greeanbrain/image/upload/v1650988632/suburban-sales-automation-assets/cutomer.svg',
            })
            this.addMarker({
                lat: this.nodeLocation.latitude,
                lng: this.nodeLocation.longitude,
                icon:
                    'https://res.cloudinary.com/greeanbrain/image/upload/v1651130568/suburban-sales-automation-assets/fiber.svg',
            })
            this.$nextTick(() => {
                this.computeDistanceBetweenTwoPoints(this.markers[0], this.markers[1])
                this.calcRoute(this.markers[0].getPosition(), this.markers[1].getPosition())
                // this.calcRoute(this.customerLocation, { lat: latitude, lng: longitude })
            })
        }, 1000)
    },
    data() {
        return {
            map: null,
            distanceBetweenTwoPoints: [],
            markers: [],
        }
    },
    computed: {
        ...mapGetters(['customerLocation', 'nodeLocation']),
    },
    methods: {
        addMap() {
            const { lat, lng } = this.customerLocation

            this.map = new window.google.maps.Map(this.$refs.map, {
                center: { lat, lng },
                zoom: 7,
                mapTypeId: 'hybrid',
            })
        },
        addMarker(prop) {
            const marker = new window.google.maps.Marker({
                position: { lat: prop.lat, lng: prop.lng },
                icon: prop.icon,
                map: this.map,
            })
            // update markers array
            this.markers.push(marker)
        },
        computeDistanceBetweenTwoPoints(markerA, markerB) {
            const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
                markerA.getPosition(),
                markerB.getPosition()
            )

            return distanceInMeters
        },
        calcRoute(start, end) {
            const directionsService = new window.google.maps.DirectionsService()
            const directionsRenderer = new window.google.maps.DirectionsRenderer({
                suppressMarkers: true,
            })

            const request = {
                origin: start,
                destination: end,
                travelMode: 'WALKING',
            }

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result)
                    directionsRenderer.setMap(this.map)
                }
            })
        },
    },
}
</script>

<style scoped>
#map {
    width: 100%;
    height: 60vh;
}
</style>
